body {
	margin: 0;
	font-family: "Roboto Condensed", sans-serif;
	/* font-variant: small-caps; */
	letter-spacing: 0.5px;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	overflow: visible;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

/* p {
	line-height: 5px;
} */

h1,
h3,
.fontvariantsc {
	font-variant: small-caps;
}

.pageFooter {
	height: 35%;
	position: bottom;
}

.iconContainer {
	height: 35px;
	width: 35px;
	border-radius: 50%;
}

.hrLine {
	width: 85vw;
	border-color: rgba(80, 80, 80, 0.4);
}

/* Transitions */

/* @keyframes cardMoveInLeft {
	0% {
		opacity: 0;
		transform: translateX(-100rem);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes cardMoveInRight {
	0% {
		opacity: 0;
		transform: translateX(100rem);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes cardMoveInBottom {
	0% {
		opacity: 0;
		transform: translateY(-100rem);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes cardMoveInTop {
	0% {
		opacity: 0;
		transform: translateY(-100rem);
	}
	100% {
		opacity: 1;
		transform: translateY(0);
	}
} */

/* Link */
.rr_link:link,
.rr_link:hover,
.rr_link:visited,
.rr_link:active {
	text-decoration: none;
	/* color: white; */
	color: #000;
	text-shadow: 2px 4px 3px #00000063;
	background-color: #fff;
	border: none;
	border-radius: 25px;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	transition: all 0.5s ease 0s;
	cursor: pointer;
	outline: none;
}

a {
	text-decoration: none;
}

.card-img {
	filter: brightness(0.65) contrast(100%);
	transition: all 0.3s ease-in-out;
}
.card:hover .card-img {
	transition: all 0.6s ease-in-out;
	filter: brightness(1.1) contrast(110%);
	transform: scale(1.04);
	box-shadow: 0 2rem 3rem rgba(0, 0, 0, 0.2);
}

.card-img-overlay > .rr_link {
	visibility: hidden;
}

.card-img-overlay > .btn {
	visibility: hidden;
}

.card-img-overlay:hover .rr_link {
	visibility: visible;
}

.card-img-overlay:hover .btn {
	visibility: visible;
}

.img:active {
	transform: translateY(-1px);
	box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.card {
	border: none;
}

.text-shadow {
	text-shadow: 1px 3px 3px #00000046;
}

/* Buttons */
.btn-sm-featured {
	width: 100px;
	letter-spacing: 0.8px;
	font-weight: 500;
	color: #000;
	text-shadow: 2px 4px 3px #00000063;
	background-color: #fff;
	border: none;
	border-radius: 25px;
	box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
	transition: all 0.5s ease 0s;
	cursor: pointer;
	outline: none;
}

.btn:hover,
.rr_link:hover {
	transform: translateY(-5px);
}

.customModal {
	width: 70vw;
	top: 20%;
}

.modal-90w {
	max-width: 70% !important;
}

.carousel-inner {
	background-color: rgb(36, 36, 36);
}

.carousel-item {
	width: 100%;
	height: 80vh;
	background-color: "black";
}

.carousel-item img {
	width: 100%;
	height: 100%;
	object-fit: contain;
}

.about-profileimg,
.about-clientimg {
	transition: all 0.5s ease 0s;
	object-fit: contain;
	padding: 0;
}

.about-profileimg:hover {
	transform: translate(5px, -5px);
	box-shadow: -12px 20px 23px 0px rgba(0, 0, 0, 0.7);
}

.about-clientimg:hover {
	transform: translate(-5px, -5px);
	box-shadow: 12px 20px 23px 0px rgba(0, 0, 0, 0.7);
}

.titlehead {
	color: transparent;
	background: #666666;
	-webkit-background-clip: text;
	-moz-background-clip: text;
	background-clip: text;
	text-shadow: 0px 3px 3px rgba(255, 255, 255, 0.5);
}

#about-title {
	font-weight: 700;
}

.about-card {
	border-left: 10px ridge rgb(121, 121, 121);
	border-right: 10px ridge rgb(121, 121, 121);
	/* border: 2px outset rgba(128, 128, 128, 0.76); */
	box-shadow: 2px 20px 23px 0px rgba(0, 0, 0, 0.5);
}

.featurecarousel {
	width: 85%;
}

.featurecarousel-item {
	max-height: 65vh;
	/* width: 100%; */
}

.featurecarousel img {
	/* height: 65vh; */
	/* width: 100%; */
	/* object-fit: "cover"; */
}

#featurecarousel-imgs {
	object-fit: cover;
}

.featured-cards {
	height: 35vh;
}

.featured-cards .card-img {
	height: 100%;
	object-fit: cover;
}

.nav-link {
	letter-spacing: 1px;
}

.projectpage-cards {
	height: 50vh;
	border-top: 8px ridge rgba(121, 121, 121, 0.8);
	border-bottom: 8px ridge rgba(121, 121, 121, 0.8);
}

.projectpage-cards .card-img {
	height: 100%;
	object-fit: cover;
}

.modal-content {
	border: none;
	background: none;
	width: 100%;
	height: 100%;
}

#contained-modal-title {
	color: white;
}

.close {
	color: white;
}

.modal-backdrop.fade {
	opacity: 0.85;
}

#carnewr .carousel,
#carcom .carousel,
#carrem .carousel,
#carsei .carousel,
#awardcar .carousel {
	width: 100vw;
}

#carnewr .carousel-inner,
#carcom .carousel-inner,
#carrem .carousel-inner,
#carsei .carousel-inner,
#awardcar .carousel-inner {
	height: 100%;
	width: 100%;
	background: none;
}

#carnewr img,
#carcom img,
#carrem img,
#carsei img,
#awardcar img {
	height: 100%;
	object-fit: "cover";
}

#carnewr .carousel-control-prev,
#carnewr .carousel-control-next,
#carcom .carousel-control-prev,
#carcom .carousel-control-next,
#carrem .carousel-control-prev,
#carrem .carousel-control-next,
#carsei .carousel-control-prev,
#carsei .carousel-control-next,
#awardcar .carousel-control-prev,
#awardcar .carousel-control-next {
	opacity: 1;
	width: 5%;
}

.aboutsec-titles {
	text-shadow: 1px 0px 1px #cccccc, 0px 1px 1px #eeeeee, 2px 1px 1px #cccccc, 1px 2px 1px #eeeeee, 3px 2px 1px #cccccc, 2px 3px 1px #eeeeee, 4px 3px 1px #cccccc, 3px 4px 1px #eeeeee, 5px 4px 1px #cccccc, 4px 5px 1px #eeeeee, 6px 5px 1px #cccccc, 5px 6px 1px #eeeeee, 7px 6px 1px #cccccc;
}

#bannerslider {
	object-fit: cover;
}

.bnslider-item {
	max-height: 15vh;
	padding: 0;
	margin: 0;
}

.bnslider-item img {
	opacity: 0.7;
}

#banner-title {
	font-size: 2.75rem;
	font-weight: 700;
	text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

#seismicmini-item {
}

.seismicmini-item {
	max-height: 45vh;
}

.seismicmini-item img {
	object-fit: cover;
}
.award-card {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
}

.award-card .card-img {
	height: 25%;
	object-fit: cover;
	padding: 0;
}

.license-card {
	border: 1px inset black;
	background-color: rgba(207, 207, 207, 0.384);
}

.cred-titles {
	font-weight: 800;
}
